(function () {
    /**
     * ----------------------
     * ---- VUE COMPONENT ---
     * ----------------------
     *
     * - Login modal
     * -- Modal to allow users to login outside the main login page
     *
     * - Dependencies
     * -- Bootstrap
     * -- FingerprintJS
     * -- gtag
     */
    Vue.component("login-modal", {
        props: [
            "isModalOpen",
            "state", //The social login params DTO {step,language,securityToken,id}
            "recaptchaModule", //Google Recaptcha (recaptcha.jsp)
            "socialLoginModule", //social-login.js
            "nextNexthop", //optional value, by default the nexthop will be the actual page
            "i18n",
            "bannerText", //optional, text already localized
            "minimalistMode", //optional, boolean, default false.
        ],
        data: function () {
            return {
                language: this.state.language,
                email: "",
                password: "",
                visitorId: "",
                confidence: "",
            };
        },
        template: `
 <!-- Login Modal -->
  <div v-if="isModalOpen" class="login-modal--mask" aria-labelledby="Log in to Wikiloc"
     aria-modal="true"
     :aria-hidden="isModalOpen"
     ref="loginModal"
     v-cloak>
    <div class="login-modal__wrapper">
      <div class="login-modal__content"  :class="{'login-modal__content--minimalist': minimalistMode}" id="login-modal" ref="loginModalContent">
       <div v-if="!minimalistMode" class="login-modal__close">
        <button type="button" class="login-modal__close-button" aria-label="Close" @click="close">
          <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/modal_cross.svg">
        </button>
       </div>
       <div class="login-modal__signup-login">
        <div v-if="!minimalistMode" class="login-modal__signup">
          <h2 class="login-modal__signup-title">{{ i18n.txtLoginModalSignupTitle }}</h2>
          <ul class="login-modal__signup-list">
            <li><span class="glyphicon icon-check"></span>{{ i18n.txtLoginModalSignupWhyA }}</li>
            <li><span class="glyphicon icon-check"></span>{{ i18n.txtLoginModalSignupWhyB }}</li>
            <li><span class="glyphicon icon-check"></span>{{ i18n.txtLoginModalSignupWhyC }}</li>
            <li><span class="glyphicon icon-check"></span>{{ i18n.txtLoginModalSignupWhyD }}</li>
          </ul>
          <button class="btn btn-warning login-modal__signup-create" @click="createAccount">{{ i18n.txtLoginModalSignupButton }}</button>
        </div> 
        <div class="login-modal__login">
          <div v-if="bannerText" class="login-modal__banner">{{bannerText}}</div>
          <h2 class="login-modal__login-title">{{ i18n.txtLoginModalLoginTitle }}</h2>
          <div class="login-modal__login-subtitle">{{ i18n.txtLoginModalLoginSubtitle }}&nbsp;
            <span v-html="i18n.txtLoginModalLoginCreateAccount"></span>
          </div>
            <form name="loginForm" id="login" method="post" :action='"/wikiloc/login.do?NEXTHOP="+ nexthop'>
              <input type="text" name="email" v-model="email" :placeholder="i18n.txtLoginModalLoginEmailUsername" class="login-modal__input"/>
              <input type="password" name="password" v-model="password" :placeholder="i18n.txtLoginModalLoginPwd" class="login-modal__input login-modal__input-password"/>
              <input type="hidden" name="visitorId" v-model="visitorId" />
              <input type="hidden" name="confidence" v-model="confidence" />
              <div class="login-modal__login-forgot">
                <a class="wllink-simple" href="/wikiloc/forgotPassword.do">
                  {{ i18n.txtLoginModalLoginForgotPwd }}
                </a>
              </div>
              <button class="btn btn-success btn-block" id="btn-login" >{{ i18n.txtLoginModalLoginButton }}</button>
              <div class="login-modal__or">{{ i18n.txtLoginModalLoginOr }}</div>
              <div id="appleid-signin" data-color="white" data-border="false" data-type="sign in"></div>
              <button id="google-signin" v-bind:data-hl="language" type="button" class="btn btn-default btn-block"></button>
              <p class="login-modal__login-terms" v-html="i18n.txtLoginModalTerms"></p>
            </form>
          </div>
        </div>
      </div>
    </div> 
  </div>
`,
        methods: {
            createAccount: createAccount,
            onClickOutside: onClickOutside,
            close: close,
        },
        computed: {
            nexthop: nexthop,
        },
        watch: {
            isModalOpen: isModalOpen,
        },
        //VUE LIFECYCLE
        mounted: mounted,
        beforeDestroy: beforeDestroy,
    });

    /**
     * -----------------
     * ---- CONSTANTS --
     * -----------------
     */

    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */
    function createAccount() {
        window.location = "/wikiloc/signingup.do";
    }

    function close() {
        this.$emit("closeLoginModalEvent", {});
        this.recaptchaModule.unloadRecaptcha();
    }

    function onClickOutside(event) {
        // stop click propagation for parent modals
        // without this action, the clickOutside event is triggered in the parent modal, closing it as well.
        event.stopPropagation();

        const el = this.$refs.loginModalContent;
        if (el && !el.contains(event.target)) {
            this.$emit("closeLoginModalEvent", {});
            this.recaptchaModule.unloadRecaptcha();
        }
    }

    /**
     * ---- COMPUTED ATTRIBUTES ----
     * https://vuejs.org/v2/guide/computed.html#Computed-Properties
     */

    /**
     * The nexthop is a url in base64 (with UTF-8 encoding)
     */
    function nexthop() {
        if (this.nextNexthop) {
            return this.nextNexthop;
        }
        return window.btoa(window.location.href);
    }

    /**
     * ------------------
     * ---- WATCHERS ----
     * ------------------
     * When Vue reactivity is not enough
     * https://vuejs.org/v2/guide/computed.html#Watchers
     */

    function isModalOpen(newIsModalOpen, oldIsModalOpen) {
        if (newIsModalOpen && !oldIsModalOpen) {
            gtagEvent("login_start");
        }
        _doubleRaf(() => {
            if (newIsModalOpen) {
                _addOnClickOutsideEvent(this);
                this.state.nexthop = this.nexthop;
                this.state.visitorId = this.visitorId;
                this.state.confidence = this.confidence;
                this.socialLoginModule.forceInit(this.state);
                this.recaptchaModule.loadRecaptcha({ forceReload: true });
                _enableDocumentScroll(false);
            } else {
                _enableDocumentScroll(true);
            }
        });
    }

    /**
     * -----------------------
     * ---- VUE LIFECYCLE ----
     * -----------------------
     * https://vuejs.org/v2/guide/instance.html#Lifecycle-Diagram
     */

    /**
     * Called after the instance has been mounted, where el is replaced by the newly created vm.$el.
     * If the root instance is mounted to an in-document element, vm.$el will also be in-document when mounted is called.
     *
     * Note that mounted does not guarantee that all child components have also been mounted.
     * If you want to wait until the entire view has been rendered, you can use vm.$nextTick inside of mounted
     */

    function mounted() {
        this.$nextTick(function () {
            //Init
            let that = this;
            FingerprintJS.load()
                .then(function (fp) {
                    return fp.get();
                })
                .then(function (fingerprint) {
                    that.visitorId = fingerprint.visitorId;
                    that.confidence = fingerprint.confidence.score;
                    //As this operation is done in the $nextTick we need to update the state
                    // or the variable wouldn't have this values
                    that.state.visitorId = that.visitorId;
                    that.state.confidence = that.confidence;
                });
            //Prop to data initialization
            //Vue eventbus Listeners
        });
    }

    /**
     * Called right before a Vue instance is destroyed. At this stage the instance is still fully functional.
     */
    function beforeDestroy() {
        _rmOnClickOutsideEvent(this);
    }

    /**
     * -------------------------
     * ---- PRIVATE METHODS ----
     * -------------------------
     * Remember to use .call() if you need access to Vue scope!
     */

    /**
     * Enable or disable global document scroll.
     *
     * WARN: It breaks the encapsulation of the component, but it is the only way
     * to block the scroll for the modals
     *
     * @param enable: true/false to enable or disable the document scroll
     */
    function _enableDocumentScroll(enable) {
        let value = enable ? "auto" : "hidden";

        document.body.style.overflow = value;
    }

    /**
     * EXPERT TIP.
     * Wait the double of frames than vue.nextTick()
     * Sometimes the computation is much bigger than what nextTick can handle.
     * We need to wait the double of frames: https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
     * @param callback
     */
    function _doubleRaf(callback) {
        requestAnimationFrame(() => {
            requestAnimationFrame(callback);
        });
    }

    function _addOnClickOutsideEvent(vueInstance) {
        vueInstance.$refs.loginModal.addEventListener(
            "click",
            vueInstance.onClickOutside
        );
    }

    function _rmOnClickOutsideEvent(vueInstance) {
        // if the login-modal was called from another component, closing the parent
        //     may destroy the component before calling its inner methods.
        // To prevent a console error, check first that the reference is still active
        if (vueInstance.$refs.loginModal) {
            vueInstance.$refs.loginModal.removeEventListener(
                "click",
                vueInstance.onClickOutside
            );
        }
    }

    /**
     * --------------
     * ---- DTOs ----
     * --------------
     *
     */
})();
